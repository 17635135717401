import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import UpgradeGuide from '@workday/canvas-kit-docs/dist/mdx/7.0-UPGRADE-GUIDE.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Breadcrumbs = makeShortcode("Breadcrumbs");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Breadcrumbs items={[{
      name: 'Upgrade Guides',
      to: '/help/upgrade-guides'
    }, {
      name: 'Canvas Kit v7 Upgrade Guide'
    }]} mdxType="Breadcrumbs" />
    <UpgradeGuide mdxType="UpgradeGuide" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      